<template>
    <div class="lab-submit">
        <div class="main" v-if="isLogin">
            <div class="side-fixed">

            </div>
            <div class="detail">
                <div class="content">
                    <router-view />
                </div>
            </div>
        </div>
        <div v-else style="margin-top: 150px">
            <el-empty description="登录后可继续操作">
                <el-button type="danger" @click="$login.show()">去登录</el-button>
            </el-empty>
        </div>
    </div>
</template>

<script>
import {  mapGetters } from 'vuex'
    export default {
        name: "submit",
      computed: {
        ...mapGetters(['isLogin']),
      }
    }
</script>

<style scoped>

</style>